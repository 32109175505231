import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"


const WorkPage = (props) => {
    const { data } = props;

    console.log('>>>', data);
    let work = data.work.nodes[0];
    console.log('doc -->', work);
    if (!work) return null;

    work = work.data;
    const caseStudies = data.case_studies.nodes;
    const featuredCaseStudy = caseStudies.filter(d => d.uid  === work.featured_case_study.uid )[0];

    console.log("WORK", work);
    console.log("CASE STUDIES", caseStudies);
    console.log("Featured CASE STUDy", featuredCaseStudy);

    return (
        <Layout>
            <SEO title={ work.meta_title } image={ work.meta_og_image.url } description={ work.meta_description } canonical={ typeof window !== 'undefined' && (window.location.origin + window.location.pathname)} />
            <div className="title-bar banner-work" alt="" style={{ backgroundImage: `url(${work.wide_image.url})`}}>
                <div className="container">
                    <div className="wrapper">
                        <h1 className="page-title">Work</h1>
                        <div className="title-bar-accent"></div>
                    </div>
                </div>
            </div>

            {/*  PAGE BODY  */}
            <section id="body-area">

                <div className="container">

                    <div className="inset">
                        <div className="row">
                            <div className="col-md-6"><a href={`/case-study/${ featuredCaseStudy.uid }`}><img src={ featuredCaseStudy.data.case_study_spot_image.url } className="h-fill" alt={ featuredCaseStudy.data.case_study_spot_image.alt } /></a></div>
                            <div className="col-md-6 margin-bottom-20">
                                <h2 className="headline margin-bottom-20 margin-top-10">{ featuredCaseStudy.data.case_study_title.text }</h2>
                                <p className="beta">{ featuredCaseStudy.data.case_study_tagline }</p>
                                <p dangerouslySetInnerHTML={{ __html: featuredCaseStudy.data.case_study_summary.html }}></p>
                                <a href={`/case-study/${ featuredCaseStudy.uid }/`} className="txt-21 tk-klavika-web-disp-cond">VIEW CASE &#8594;</a>
                            </div>
                        </div>
                    </div>

                    <div className="box style-1 margin-bottom-25">

                        <ul className="post-list list-unstyled row">
                            {
                                caseStudies.map( (study, i) => {
                                    return(
                                        <li className="col-md-4">
                                            <a href={`/case-study/${ study.uid }/`}>
                                                <figure className="style-1 vertical">
                                                    <img src={ study.data.case_study_spot_image.url } className="h-fill" alt={ study.data.case_study_spot_image.alt } />
                                                    <figcaption>
                                                        <h3 className="title">{ study.data.case_study_title.text }</h3>
                                                        <p className="description">{ study.data.case_study_tagline }</p>
                                                    </figcaption>
                                                </figure>
                                            </a>
                                        </li>
                                    );
                                })
                            }
                        </ul>

                    </div> {/*  /box  */}

                </div> {/*  /container  */}

            </section>

        </Layout>
    );
}

export default WorkPage;

export const workPageQuery = graphql`
query workQuery {
    work: allPrismicWorkPage {
      nodes {
        data {
          featured_case_study {
            uid
          }
          wide_image {
            url
            dimensions {
              width
              height
            }
          }
          meta_title
          meta_description
          meta_og_image {
            url
          }
        }
      }
    }
    case_studies: allPrismicCaseStudy {
      nodes {
        data {
          body {
            ... on PrismicCaseStudyBodyCalloutBox {
              id
              slice_type
              primary {
                callout_box_body_text {
                  html
                }
                callout_box_cta_button {
                  url
                }
                callout_box_label {
                  text
                }
              }
            }
          }
          case_study_body_text {
            html
            text
          }
          case_study_spot_image {
            alt
            url
          }
          case_study_summary {
            html
            text
          }
          case_study_tagline
          case_study_wide_image {
            alt
            url
          }
          case_study_title {
            text
          }
          meta_description
          meta_og_image {
            url
          }
          meta_title
        }
        uid
      }
    }
  }
`
